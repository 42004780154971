"use client";

import { useMainLayout } from "@/app/context/mainLayoutProvider";
import React from "react";
import LinkItem from "./LinkItem";
import { navItems } from "@/lib/utils";
import { useGlobalStore } from "@/app/context/globalStoreProvider";
import { useUI } from "@/app/context/UIProvider";

type Props = {
  isLoggedIn?: boolean;
};

const MiddleComponent: React.FC<Props> = ({ isLoggedIn }) => {
  const { renderMiddleContent } = useMainLayout();
  const { user, isEventPage } = useGlobalStore();

  const { isLessThanMd } = useUI();

  if (renderMiddleContent) return renderMiddleContent;
  if (!isLoggedIn) {
    return null;
  }
  return (
    <div className="flex h-full w-full items-center justify-center gap-6 text-xl">
      {!isLessThanMd && user?.status === "EMAIL_SYNCED" && !isEventPage
        ? navItems.map((item) => (
            <LinkItem
              path={item.href}
              key={item.key}
              className="flex items-center gap-1"
            >
              {item.title}
              {item.key === "hotel-search-researcher" ? (
                <div className="rounded-md bg-gondola-blue px-2 py-1 text-sm">
                  Beta
                </div>
              ) : null}
            </LinkItem>
          ))
        : null}
    </div>
  );
};

export default MiddleComponent;
